import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  MDBContainer,
  MDBDataTableV5,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import Switch from "react-switch";
import { baseUrl } from "../../config/libs";
import "./style.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smdatatable: {
        columns: [
          {
            label: "Company Name",
            field: "compName",
            width: 250,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "Name",
            },
          },
          {
            label: "Action",
            field: "action2",
            sort: "disabled",
            width: 150,
          },
        ],
        rows: [],
      },
      smuserdatatable: {
        columns: [
          {
            label: "Name",
            field: "userName",
            width: 250,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "Name",
            },
          },
          {
            label: "Action",
            field: "action2",
            sort: "disabled",
            width: 150,
          },
        ],
        rows: [],
      },
      datatable: {
        columns: [
          {
            label: "Company Name",
            field: "compName",
            width: 250,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "Name",
            },
          },
          {
            label: "Subs. Cost",
            field: "subCost",
            sort: "disabled",
            width: 50,
          },
          {
            label: "Expiry Date",
            field: "nextEdate",
            sort: "disabled",
            width: 50,
          },
          {
            label: "Status",
            field: "isB",
            sort: "disabled",
            width: 50,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 150,
          },
        ],
        rows: [],
      },
      userdatatable: {
        columns: [
          {
            label: "User Name",
            field: "userName",
            width: 250,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "Name",
            },
          },
          {
            label: "Phone",
            field: "phone",
            sort: "disabled",
            width: 50,
          },
          {
            label: "Email",
            field: "email",
            sort: "disabled",
            width: 50,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 150,
          },
        ],
        rows: [],
      },
      redirect: false,
      user: {},
      loading: false,
      customers: [],
      users: {},
      detailsModal: false,
      subsModal: false,
      addCstModal: false,
      delModal: false,
      userModal: false,
      addUserModal: false,
      notiModal: false,
      cst: {},
      check: false,
      subCost: "",
      subDue: "",
      name: "",
      phone: "",
      email: "",
      dAddress: "",
      password: "",
      id: "",
      tRev: 0,
      low: [],
      dtp: "",
      show: true,
    };
  }

  toggleDetails = (cst) => {
    this.setState({ detailsModal: !this.state.detailsModal, cst: cst });
  };

  toggleSubs = (cst) => {
    if (cst === undefined || cst === null) {
      this.setState({ subsModal: !this.state.subsModal });
    } else {
      if (cst.isB.props.children === "Active") {
        this.setState({
          subsModal: !this.state.subsModal,
          cst: cst,
          check: false,
        });
      } else {
        this.setState({
          subsModal: !this.state.subsModal,
          cst: cst,
          check: true,
        });
      }
    }
  };

  toggleAddCst = () => {
    this.setState({ addCstModal: !this.state.addCstModal });
  };

  toggleNoti = () => {
    this.setState({ notiModal: !this.state.notiModal });
  };

  toggleAddUser = () => {
    this.setState({ addUserModal: !this.state.addUserModal });
  };

  toggleDell = (id, dtp) => {
    this.setState({ delModal: !this.state.delModal, id, dtp });
  };

  toggleUser = (users) => {
    this.setState({ userModal: !this.state.userModal, users });
  };

  componentDidMount() {
    this.userData();
    this.getCustomers();
    this.getRevs();
    this.getUsers();
  }

  async userData() {
    const us = localStorage.getItem("user");
    const user = await JSON.parse(us);
    const lg = localStorage.getItem("isLoged");
    if (lg !== "true") {
      this.setState({ redirect: true });
    } else {
      this.setState({ user: user });
      this.dispName();
    }
  }

  dispName(check) {
    const userName = this.state.user.userName;
    if (userName !== undefined && userName !== null) {
      const name = userName.substring(0, 2).toUpperCase();
      if (check === "profile") {
        return <div className="name_acronym">{name}</div>;
      } else {
        return <div className="rounded-circle header-profile-user">{name}</div>;
      }
    } else {
      return <div className="name_acronym"></div>;
    }
  }

  _logout() {
    localStorage.clear();
    this.setState({ redirect: true });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <body data-layout="horizontal" data-topbar="dark">
        <div className="container-fluid">
          <div className="layout-wrappers">
            <header className="tb">
              <div className="navbar-header">
                <div className="d-flex">
                  <div className="navbar-brand-box mr-50">
                    <span className="logo logo-dark">
                      <span className="logo-sm">
                        <img
                          src="../assets/images/log-1.png"
                          alt="log"
                          height={20}
                        />
                        <span style={{ color: "white", fontSize: "10px" }}>
                          S.W.B.R
                        </span>
                      </span>
                      <span className="logo-lg">
                        <img
                          src="../assets/images/log-1.png"
                          alt="log"
                          height={18}
                        />
                        <span style={{ color: "white", fontSize: "25px" }}>
                          SIMPLIFIED WEEKLY BUSSINESS RECORDS
                        </span>
                      </span>
                    </span>
                    <span className="logo logo-light">
                      <span className="logo-lg">
                        <img
                          src="../assets/images/log-1.png"
                          alt="log"
                          height={50}
                        />
                        <span style={{ color: "white", fontSize: "25px" }}>
                          SIMPLIFIED WEEKLY BUSSINESS RECORDS
                        </span>
                      </span>
                    </span>
                  </div>
                  <button
                    type="arrow"
                    class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                  >
                    <img
                      src="../assets/images/log-1.png"
                      alt="log"
                      height={30}
                    />
                    <span style={{ color: "white", fontSize: "10px" }}>
                      S.W.B.R
                    </span>
                  </button>
                </div>

                <div className="d-flex">
                  <div className="dropdown d-inline-block">
                    <div
                      className="dropdown d-inline-block"
                      type="button"
                      onClick={() => this.toggleNoti()}
                    >
                      {this.state.low.length === 0 ? (
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                        >
                          <i className="fas fa-bell" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                        >
                          <i className="fas fa-bell" />
                          <span className="badge-danger badge-pill">
                            {this.state.low.length}
                          </span>
                        </button>
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn header-item waves-effect"
                      id="page-header-user-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.dispName("header")}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {/* item*/}
                      <span
                        className="dropdown-item d-block d-sm-none"
                        type="button"
                      >
                        <i className="fa fa-user font-size-16 align-middle mr-1" />{" "}
                        {this.state.user.userName !== undefined &&
                        this.state.user.userName
                          ? this.state.user.userName
                          : ""}
                      </span>
                      <div className="dropdown-divider d-block d-sm-none" />
                      <span className="dropdown-item d-block d-sm-none">
                        <i className="fa fa-phone font-size-16 align-middle mr-1" />{" "}
                        {this.state.user.phone !== undefined &&
                        this.state.user.phone
                          ? this.state.user.phone
                          : ""}
                      </span>
                      <div className="dropdown-divider d-block d-sm-none" />
                      <span
                        className="dropdown-item d-block d-sm-none"
                        type="button"
                      >
                        <i className="fa fa-envelope-square font-size-16 align-middle mr-1" />{" "}
                        {this.state.user.email !== undefined &&
                        this.state.user.email
                          ? this.state.user.email
                          : ""}
                      </span>
                      <div className="dropdown-divider d-block d-sm-none" />
                      <span
                        className="dropdown-item"
                        type="button"
                        onClick={() => this.toggleAddCst()}
                      >
                        <i className="fa fa-user-tie font-size-16 align-middle mr-1" />{" "}
                        Create Customer
                      </span>
                      <div className="dropdown-divider" />
                      <span
                        className="dropdown-item"
                        type="button"
                        onClick={() => this.toggleAddUser()}
                      >
                        <i className="fa fa-user-plus font-size-16 align-middle mr-1" />{" "}
                        Add User
                      </span>
                      <div className="dropdown-divider" />
                      <span
                        type="button"
                        onClick={() => this.toastInfo()}
                        className="dropdown-item"
                      >
                        <i className="fa fa-info-circle font-size-16 align-middle mr-1" />{" "}
                        Support
                      </span>
                      <div className="dropdown-divider" />
                      <span
                        type="button"
                        onClick={() => this._logout()}
                        className="dropdown-item text-danger"
                      >
                        <i className="fa fa-power-off font-size-16 align-middle mr-1 text-danger" />{" "}
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="main-content mt-3">
              <div className="page-content">
                <div className="row">
                  <div className="col-md-12 col-xl-3 d-none d-sm-block">
                    <div className="card">
                      <div className="card-body">
                        <div className="profile-widgets py-3">
                          <div className="text-center">
                            <div className>
                              <div className="avatar-lg mx-auto img-thumbnail rounded-circle">
                                {this.dispName("profile")}
                              </div>
                              <div className="online-circle">
                                <i className="fas fa-circle text-success" />
                              </div>
                            </div>
                            <div className="mt-3 ">
                              <span className="text-dark font-weight-medium font-size-16">
                                {this.state.user.userName !== undefined &&
                                this.state.user.userName
                                  ? this.state.user.userName
                                  : ""}
                              </span>
                            </div>
                            <div className="row mt-4 border border-left-0 border-right-0 p-3">
                              <div className="col-md-12">
                                <h6 className="text-muted">Phone</h6>
                                <h5 className="mb-0">
                                  {this.state.user.phone !== undefined &&
                                  this.state.user.phone
                                    ? this.state.user.phone
                                    : ""}
                                </h5>
                              </div>
                            </div>
                            <div className="row mt-4 border border-left-0 border-right-0 p-3">
                              <div className="col-md-12">
                                <h6 className="text-muted">Email</h6>
                                <h5 className="mb-0">
                                  {this.state.user.email !== undefined &&
                                  this.state.user.email
                                    ? this.state.user.email
                                    : ""}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-9">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-8">
                                <p className="mb-2">Total Customers</p>
                                <h4 className="mb-0">
                                  {this.state.customers.length}
                                </h4>
                              </div>
                              <div className="col-4">
                                <div className="text-right">
                                  <div>{this.cstP()}%</div>
                                  <div className="progress progress-sm mt-3">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: this.cstP() }}
                                      aria-valuenow={62}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-8">
                                <p className="mb-2">Total Revenue</p>
                                <h4 className="mb-0">GHC {this.state.tRev}</h4>
                              </div>
                              <div className="col-4">
                                <div className="text-right">
                                  <div>{this.revP()}%</div>
                                  <div className="progress progress-sm mt-3">
                                    <div
                                      className="progress-bar bg-warning"
                                      role="progressbar"
                                      style={{ width: this.revP() }}
                                      aria-valuenow={78}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body d-block d-sm-none">
                        {/* Nav tabs */}
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-justified"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#experience"
                              role="tab"
                            >
                              <span className="">
                                <i className="fas fa-user-tie" />
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#settings"
                              role="tab"
                            >
                              <span className="">
                                <i className="fas fa-users" />
                              </span>
                            </a>
                          </li>
                        </ul>
                        {/* small tables */}
                        <div className="tab-content p-3 text-muted">
                          <div
                            className="tab-pane active"
                            id="experience"
                            role="tabpanel"
                          >
                            {this.state.loading ? (
                              <BarLoader
                                color="blue"
                                height={4}
                                width={100}
                                loading={this.state.loading}
                                css={override}
                              />
                            ) : (
                              <MDBDataTableV5
                                hover
                                entriesOptions={[5, 20, 25]}
                                entries={10}
                                pagesAmount={20}
                                data={this.state.smdatatable}
                                searchTop
                                searchBottom={false}
                              />
                            )}
                          </div>
                          <div
                            className="tab-pane"
                            id="settings"
                            role="tabpanel"
                          >
                            {this.state.loading ? (
                              <BarLoader
                                color="blue"
                                height={4}
                                width={100}
                                loading={this.state.loading}
                                css={override}
                              />
                            ) : (
                              <MDBDataTableV5
                                hover
                                entriesOptions={[5, 20, 25]}
                                entries={10}
                                pagesAmount={20}
                                data={this.state.smuserdatatable}
                                searchTop
                                searchBottom={false}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="card-body d-none d-sm-block">
                        {/* Nav tabs */}
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-justified"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <span
                              className="nav-link active"
                              data-toggle="tab"
                              href="#experience"
                              role="tab"
                              type="button"
                              onClick={() =>
                                this.setState({ show: !this.state.show })
                              }
                            >
                              <span className="">Customers List</span>
                            </span>
                          </li>
                          <li className="nav-item">
                            <span
                              className="nav-link"
                              data-toggle="tab"
                              href="#settings"
                              role="tab"
                              type="button"
                              onClick={() =>
                                this.setState({ show: !this.state.show })
                              }
                            >
                              <span className="">Users</span>
                            </span>
                          </li>
                        </ul>
                        {/* Tab panes */}
                        <div className="tab-content p-3 text-muted">
                          {this.state.show ? (
                            <div
                              className="tab-pane active"
                              id="experience"
                              role="tabpanel"
                            >
                              {this.state.loading ? (
                                <BarLoader
                                  color="blue"
                                  height={4}
                                  width={100}
                                  loading={this.state.loading}
                                  css={override}
                                />
                              ) : (
                                <MDBDataTableV5
                                  hover
                                  entriesOptions={[5, 20, 25]}
                                  entries={5}
                                  pagesAmount={10}
                                  data={this.state.datatable}
                                  searchTop
                                  searchBottom={false}
                                />
                              )}
                            </div>
                          ) : (
                            <div
                              className="tab-pane active"
                              id="experience"
                              role="tabpanel"
                            >
                              {this.state.loading ? (
                                <BarLoader
                                  color="blue"
                                  height={4}
                                  width={100}
                                  loading={this.state.loading}
                                  css={override}
                                />
                              ) : (
                                <MDBDataTableV5
                                  hover
                                  entriesOptions={[5, 20, 25]}
                                  entries={5}
                                  pagesAmount={10}
                                  data={this.state.userdatatable}
                                  searchTop
                                  searchBottom={false}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.renderModal()}
          </div>
        </div>
      </body>
    );
  }

  loader = () => {
    this.setState({ loading: !this.state.loading });
  };

  toastHandler = (msg) => {
    toast.notify(<div>{msg}</div>, {
      position: "top",
      duration: 3000,
    });
  };

  toast = (info) => {
    toast.notify(
      <div>
        Company Name: {info.compName}
        <br />
        Company Email: {info.cMail}
        <br />
        Company password: {info.compPass}
      </div>,
      {
        position: "top-right",
        duration: null,
      }
    );
  };

  toastInfo = () => {
    toast.notify(
      <div>
        Contact us on: +233 558 117 327 or +233 244 705 306
        <br />
        Powered by:{" "}
        <span
          type="button"
          style={{color: "blue"}}
          onClick={() => window.location.replace("https://www.prostechnologies.com")}
        >
          www.prostechnologies.com
        </span>
      </div>,
      {
        position: "top-right",
        duration: null,
      }
    );
  };

  async getRevs() {
    const re = await fetch(baseUrl + "revenue/getRevs");
    const rev = await re.json();
    const revs = rev.data;
    let totalRev = 0;
    revs.forEach((rv) => {
      totalRev += parseInt(rv.amount);
    });
    this.setState({ tRev: totalRev });
  }

  revP = () => {
    return ((this.state.tRev / 100000) * 100).toFixed(2);
  };

  cstP = () => {
    return ((parseInt(this.state.customers.length) / 100) * 100).toFixed(2);
  };

  async getCustomers() {
    this.loader();
    const cust = await fetch(baseUrl + "customers/getCustomers");
    const custo = await cust.json();
    const customer = custo.data;
    const customers = customer.map((cst) => {
      cst.nextEdate = new Date(cst.nextEdate).toLocaleDateString();
      cst.action2 = (
        <MDBDropdown size="sm">
          <MDBDropdownToggle color="#fff">
            <i className="fa fa-ellipsis-h" type="button" />
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDetails(cst)}
            >
              <i className="fa fa-pen-square" /> <span>Details</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem type="button" onClick={() => this.toggleSubs(cst)}>
              <i className="fa fa-sync" /> <span>Subs</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDell(cst._id, "cs")}
            >
              <i className="fa fa-trash" /> <span>Delete</span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      );
      const isB = cst.isB,
        isb = () => {
          if (isB === "true" || isB === "") {
            return <span className="badge badge-danger">Blocked</span>;
          } else {
            return <span className="badge badge-success">Active</span>;
          }
        };
      cst.isB = isb();
      cst.action = (
        <MDBDropdown size="sm">
          <MDBDropdownToggle color="#fff">
            <i className="fa fa-ellipsis-h" type="button" />
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDetails(cst)}
            >
              <i className="fa fa-pen-square" /> <span>Customer Details</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem type="button" onClick={() => this.toggleSubs(cst)}>
              <i className="fa fa-sync" /> <span>Manage Subs</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDell(cst._id, "cs")}
            >
              <i className="fa fa-trash" /> <span>Delete Customer</span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      );
      return cst;
    });
    if (customers) {
      const datatable = { ...this.state.datatable, rows: customers };
      const smdatatable = { ...this.state.smdatatable, rows: customers };
      this.setState({ datatable: datatable, customers, smdatatable });
      this.loader();
      this.cstLow(customers);
    } else {
      this.loader();
    }
  }

  async getUsers() {
    const cust = await fetch(baseUrl + "users/getUsers");
    const custo = await cust.json();
    const user = custo.data;
    const users = user.map((us) => {
      us.action2 = (
        <MDBDropdown size="sm">
          <MDBDropdownToggle color="#fff">
            <i className="fa fa-ellipsis-h" type="button" />
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem type="button" onClick={() => this.toggleUser(us)}>
              <i className="fa fa-pen-square" /> <span>Details</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDell(us._id, "us")}
            >
              <i className="fa fa-trash" /> <span>Delete</span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      );
      us.action = (
        <MDBDropdown size="sm">
          <MDBDropdownToggle color="#fff">
            <i className="fa fa-ellipsis-h" type="button" />
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem type="button" onClick={() => this.toggleUser(us)}>
              <i className="fa fa-pen-square" /> <span>User Details</span>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem
              type="button"
              onClick={() => this.toggleDell(us._id, "us")}
            >
              <i className="fa fa-trash" /> <span>Delete User</span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      );
      return us;
    });
    if (users) {
      const userdatatable = { ...this.state.userdatatable, rows: users };
      const smuserdatatable = { ...this.state.smuserdatatable, rows: users };
      this.setState({ userdatatable, smuserdatatable });
    } else {
      this.loader();
    }
  }

  cstLow = (cst) => {
    let low = [];
    const now = new Date();
    cst.forEach((cs) => {
      const expireDate = cs.nextEdate;
      const level = Math.round(
        (new Date(expireDate) - now) / (1000 * 60 * 60 * 24)
      );
      if ((level < 10 && level > 0) || expireDate === "") {
        low.push(cs);
      }
    });
    this.setState({ low });
  };

  save = () => {
    const { cst, subDue, subCost, check } = this.state;
    this.loader();
    let now = new Date();
    let isB = "";
    let next;
    let newSubDue;
    if (cst.subDue !== "" && subDue !== "") {
      newSubDue = parseInt(subDue) + parseInt(cst.subDue);
    } else {
      newSubDue = 0;
    }
    // if (subDue === "") {
    //   next = 0;
    // } else {
    next = new Date(now.setDate(now.getDate() + newSubDue));
    // }
    if (check === true) {
      isB = "true";
    } else {
      isB = "false";
    }
    fetch(baseUrl + "customers/updateCust/" + cst._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isB: isB,
        isInit: cst.isInit,
        nextEdate: next,
        subDue: JSON.stringify(newSubDue),
        subCost: subCost,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === 1) {
          if (subDue !== "") {
            fetch(baseUrl + "revenue/addRev/", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customer: cst.compName,
                amount: subCost,
                duration: subDue,
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.success === 1) {
                  this.setState({ subCost: "", subDue: "" });
                  this.loader();
                  this.toggleSubs();
                  this.toastHandler("Customer updated successfully");
                  this.getCustomers();
                  this.getRevs();
                } else {
                  this.loader();
                  this.toastHandler(res.message);
                }
              });
          } else {
            this.setState({ subCost: "", subDue: "" });
            this.loader();
            this.toggleSubs();
            this.toastHandler("Customer updated successfully");
            this.getCustomers();
            this.getRevs();
          }
        } else {
          this.loader();
          this.toastHandler(res.message);
        }
      });
  };

  validation = () => {
    const { name, phone, email, dAddress } = this.state;
    if (name === "" || phone === "" || email === "" || dAddress === "") {
      this.toastHandler("Please all fields are required!");
      return false;
    } else if (phone.length !== 10) {
      this.toastHandler("Please phone number is wrong!, do not include +233");
      return false;
    } else {
      return true;
    }
  };

  addCustomer = () => {
    if (this.validation()) {
      this.loader();
      const { name, phone, email, dAddress } = this.state;
      fetch(baseUrl + "customers/addCust/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          compName: name,
          phone: phone,
          cMail: email,
          dAddress: dAddress,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            const data = res.data;
            this.loader();
            this.getCustomers();
            this.toast(data);
            this.toggleAddCst();
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    }
  };

  updateUser() {
    const { name, email, phone, users } = this.state;
    if (name === "" || email === "" || phone === "") {
      this.toastHandler("Please fill all fields");
    } else if (phone.length !== 10) {
      this.toastHandler("Please phone number is wrong");
    } else {
      this.loader();
      fetch(baseUrl + "users/updateUser/" + users.email, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: name,
          email: email,
          phone: phone,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            this.getUsers();
            this.toastHandler("User updated successfully");
            this.toggleUser();
            this.loader();
            this.setState({ name: "", email: "", phone: "", users: {} });
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    }
  }

  deleteC = () => {
    const { id, dtp } = this.state;
    this.loader();
    if (dtp === "cs") {
      fetch(baseUrl + "customers/deleteCust/" + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            this.loader();
            this.getCustomers();
            this.toggleDell();
            this.toastHandler("Customer Deleted Successfully");
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    } else {
      fetch(baseUrl + "users/deleteUser/" + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            this.getUsers();
            this.toggleDell();
            this.toastHandler("User Deleted Successfully");
            this.loader();
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    }
  };

  createUser() {
    const { name, email, phone, password } = this.state;
    if (name === "" || email === "" || phone === "" || password === "") {
      this.toastHandler("Please all fields are required");
    } else if (password.length <= 6) {
      this.toastHandler("Please password is too short");
    } else if (phone.length !== 10) {
      this.toastHandler("Phone number is wrong");
    } else {
      this.loader();
      fetch(baseUrl + "users/addUser/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: name,
          phone: phone,
          email: email,
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            this.getUsers();
            this.toggleAddUser();
            this.loader();
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    }
  }

  renderModal() {
    if (this.state.addCstModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.addCstModal}
            toggle={this.toggleAddCst}
            size="lg"
          >
            <MDBModalHeader toggle={this.toggleAddCst}>
              <span className="dark">Create New Customer</span>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.loading ? (
                <BarLoader
                  color="blue"
                  height={4}
                  width={100}
                  loading={this.state.loading}
                  css={override}
                />
              ) : (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter company name"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Company Phone</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0983635564"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Company Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="example@domain.com"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">
                        Company Address(Digital Address)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GS-54-76"
                        onChange={(e) =>
                          this.setState({ dAddress: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleAddCst}>
                Cancel
              </MDBBtn>
              <MDBBtn
                color="primary"
                type="button"
                onClick={() => this.addCustomer()}
              >
                Create
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.detailsModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.detailsModal}
            toggle={this.toggleDetails}
            size="lg"
          >
            <MDBModalHeader toggle={this.toggleDetails}>
              <span className="dark">Customer Details</span>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Company Name</label>
                    <span className="form-control">
                      {this.state.cst.compName}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Phone</label>
                    <span className="form-control">{this.state.cst.phone}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Company ID</label>
                    <span className="form-control">
                      {this.state.cst.compID}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Company Email</label>
                    <span className="form-control">{this.state.cst.cMail}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Company Password</label>
                    <span className="form-control">
                      {this.state.cst.compPass}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">
                      Digital Address(Location)
                    </label>
                    <span className="form-control">
                      {this.state.cst.dAddress}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Date Added</label>
                    <span className="form-control">
                      {new Date(this.state.cst.dateAdded).toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Subscription Status</label>
                    <span className="form-control">{this.state.cst.isB}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">
                      Subscription Duration
                    </label>
                    <span className="form-control">
                      {this.state.cst.subDue}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Subscription Cost</label>
                    <span className="form-control">
                      {this.state.cst.subCost}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Next Expiry date</label>
                    <span className="form-control">
                      {this.state.cst.nextEdate}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="product-price">Initialised?</label>
                    <span className="form-control">
                      {this.state.cst.isInit}
                    </span>
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleDetails}>
                close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.subsModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.subsModal}
            toggle={this.toggleSubs}
            size="md"
          >
            <MDBModalHeader toggle={() => this.setState({ subsModal: false })}>
              <span className="dark">Manage Subscriptions</span>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.loading ? (
                <BarLoader
                  color="blue"
                  height={4}
                  width={100}
                  loading={this.state.loading}
                  css={override}
                />
              ) : (
                <div>
                  {this.state.check ? (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="product-price">Block?</label>
                          <br />
                          <Switch
                            onColor="#B71C1C"
                            onChange={(check) =>
                              this.setState({ check: check })
                            }
                            checked={this.state.check}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="product-price">Block?</label>
                          <br />
                          <Switch
                            onColor="#B71C1C"
                            onChange={(check) =>
                              this.setState({ check: check })
                            }
                            checked={this.state.check}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="product-price">
                            Subscription Duration
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please type the number of days"
                            onChange={(e) =>
                              this.setState({ subDue: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="product-price">
                            Subscription Cost
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type the cost of the subscription"
                            onChange={(e) =>
                              this.setState({ subCost: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      Note:-
                      <br />
                      if these fields are saved empty, the customer can operate
                      without paying
                    </div>
                  )}
                </div>
              )}
            </MDBModalBody>
            {this.state.check ? (
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.setState({ subsModal: false })}
                >
                  close
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  type="button"
                  onClick={() => this.save()}
                >
                  Save
                </MDBBtn>
              </MDBModalFooter>
            ) : (
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.setState({ subsModal: false })}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  type="button"
                  onClick={() => this.save()}
                >
                  Save
                </MDBBtn>
              </MDBModalFooter>
            )}
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.delModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.delModal}
            toggle={this.toggleDell}
            size="md"
          >
            <MDBModalHeader toggle={this.toggleDell}>
              <span className="dark">Delete Data</span>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.loading ? (
                <BarLoader
                  color="blue"
                  height={4}
                  width={100}
                  loading={this.state.loading}
                  css={override}
                />
              ) : (
                <div className="row">
                  <span className="danger">This action is not revesible!</span>
                </div>
              )}
            </MDBModalBody>
            {this.state.loading ? (
              <MDBModalFooter>{""}</MDBModalFooter>
            ) : (
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => this.toggleDell()}>
                  Cancel
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  type="button"
                  onClick={() => this.deleteC()}
                >
                  Continue?
                </MDBBtn>
              </MDBModalFooter>
            )}
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.userModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.userModal}
            toggle={this.toggleUser}
            size="md"
          >
            <MDBModalHeader toggle={this.toggleUser}>
              <span className="dark">User Details</span>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.loading ? (
                <BarLoader
                  color="blue"
                  height={4}
                  width={100}
                  loading={this.state.loading}
                  css={override}
                />
              ) : (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">User Name</label>:{" "}
                      {this.state.users.userName}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="agyei kofi"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Phone</label>:{" "}
                      {this.state.users.phone}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="76574895"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="product-price">Email</label>:{" "}
                      {this.state.users.email}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="example@domain.com"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </MDBModalBody>
            {this.state.loading ? (
              <MDBModalFooter>{""}</MDBModalFooter>
            ) : (
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => this.toggleUser()}>
                  Cancel
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  type="button"
                  onClick={() => this.updateUser()}
                >
                  Update User
                </MDBBtn>
              </MDBModalFooter>
            )}
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.addUserModal) {
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.addUserModal}
            toggle={this.toggleAddUser}
            size="md"
          >
            <MDBModalHeader toggle={this.toggleAddUser}>
              <span className="dark">Create User</span>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.loading ? (
                <BarLoader
                  color="blue"
                  height={4}
                  width={100}
                  loading={this.state.loading}
                  css={override}
                />
              ) : (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="agyei kofi"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="76574895"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="example@domain.com"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="product-price">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="example@domain.com"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </MDBModalBody>
            {this.state.loading ? (
              <MDBModalFooter>{""}</MDBModalFooter>
            ) : (
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => this.toggleAddUser()}>
                  Cancel
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  type="button"
                  onClick={() => this.createUser()}
                >
                  Create User
                </MDBBtn>
              </MDBModalFooter>
            )}
          </MDBModal>
        </MDBContainer>
      );
    } else if (this.state.notiModal) {
      const lw = this.state.low;
      return (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.notiModal}
            toggle={this.toggleNoti}
            size="auto"
          >
            <MDBModalHeader toggle={this.toggleNoti}>
              <span className="dark">Customer Running Low</span>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-6 d-none d-sm-block">
                  <label className="product-price">Company</label>
                </div>
                <div className="col-md-6 d-none d-sm-block">
                  <label className="product-price">Days</label>
                </div>
              </div>
              {lw.map((l) => {
                return (
                  <div className="row">
                    <div className="col-md-6 d-none d-sm-block">
                      <label className="product-price">{l.compName}</label>
                    </div>
                    <div className="col d-block d-sm-none">
                      <label className="product-price">{l.compName}</label>
                    </div>
                    <div className="col-md-3 d-none d-sm-block">
                      <label className="product-price">{l.subDue}</label>
                    </div>
                    <div className="col d-block d-sm-none">
                      <label className="product-price">{l.subDue}</label>
                    </div>
                    <div
                      className="col-md-3 dropdown-item d-none d-sm-block"
                      type="button"
                      onClick={() => this.toggleDetails(l)}
                    >
                      <i className="fa fa-eye" />
                    </div>
                    <div
                      className="col dropdown-item d-block d-sm-none"
                      type="button"
                      onClick={() => this.toggleDetails(l)}
                    >
                      <i className="fa fa-eye" />
                    </div>
                  </div>
                );
              })}
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      );
    }
  }
}

export default Dashboard;
