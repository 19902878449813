import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { baseUrl } from "../../config/libs";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: "",
      password: "",
      redirect: false,
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/main/dashboard" />;
    }
    return (
      <div>
        <div className="account-pages my-5 pt-sm-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card overflow-hidden">
                  <div className="bg-login text-center">
                    <div className="bg-login-overlay" />
                    <div className="position-relative">
                      <h5 className="text-white font-size-20">
                        SIMPLIFIED WEEKLY BUSSINESS RECORDS
                      </h5>
                      <p className="text-white-50 mb-0">
                        Sign in to continue to S.W.B.R.
                      </p>
                      <span className="logo logo-admin mt-4">
                        <img
                          src="../assets/images/log-1.png"
                          alt="logo"
                          height={50}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="card-body pt-5">
                    {this.state.loading ? (
                      <BarLoader
                        color="blue"
                        height={4}
                        width={100}
                        loading={this.state.loading}
                        css={override}
                      />
                    ) : (
                      <div>
                        <div className="p-2">
                          <form className="form-horizontal" action="index.html">
                            <div className="form-group">
                              <label htmlFor="username">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter email"
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="userpassword">Password</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="********"
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                              />
                            </div>
                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="button"
                                onClick={() => this._login()}
                              >
                                Log In
                              </button>
                            </div>
                            {/* <div className="mt-4 text-center">
                              <Link to="/recover" className="text-muted">
                                <i className="fa fa-lock mr-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <br />
                  Powered by<br/>
                  <span
                    type="button"
                    onClick={() =>
                      window.location.replace(
                        "https://www.prostechnologies.com"
                      )
                    }
                  >
                    www.prostechnologies.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  loader = () => {
    this.setState({ loading: !this.state.loading });
  };

  toastHandler = (msg) => {
    toast.notify(<div>{msg}</div>, {
      position: "top",
      duration: 3000,
    });
  };

  validation() {
    const { email, password } = this.state;
    if (email === "" || password === "") {
      this.toastHandler("Please both fields are required");
      return false;
    } else if (password.length <= 6) {
      this.toastHandler("Please pasword is too short");
      return false;
    } else {
      return true;
    }
  }

  _login() {
    if (this.validation()) {
      this.loader();
      const { email, password } = this.state;
      fetch(baseUrl + "users/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success === 1) {
            const user = res.data;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("isLoged", "true");
            this.setState({ redirect: true });
            this.loader();
          } else {
            this.loader();
            this.toastHandler(res.message);
          }
        });
    }
  }
}

export default Login;
