import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./auth/login/index";
import Dashboard from "./main/dashboard/index";
import Recover from "./auth/recoverPass/index";

function App() {
  return (
    <Router className="App">
       <Switch>
         <Route exact path="/" component={Login} />
         <Route path="/main/dashboard" component={Dashboard} />
         <Route path="/recover" component={Recover} />
       </Switch>
    </Router>
  );
}

export default App;
