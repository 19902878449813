import React, { Component } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
    };
  }

  render() {
    return (
      <div>
        <div className="account-pages my-5 pt-sm-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card overflow-hidden">
                  <div className="bg-login text-center">
                    <div className="bg-login-overlay" />
                    <div className="position-relative">
                      <h5 className="text-white font-size-20">
                        SIMPLIFIED WEEKLY BUSSINESS RECORDS
                      </h5>
                      <p className="text-white-50 mb-0">
                        Welcome to S.W.B.R.
                      </p>
                      <span className="logo logo-admin mt-4">
                        <img
                          src="../assets/images/companies/img-3.png"
                          alt="company logo"
                          height={50}
                          width={50}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="card-body pt-5">
                    {this.state.loading ? (
                      <BarLoader
                        color="blue"
                        height={4}
                        width={100}
                        loading={this.state.loading}
                        css={override}
                      />
                    ) : (
                      <div className="p-2">
                        <form className="form-horizontal" action="index.html">
                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Enter your Email to retrieve yor password
                          </div>
                          <div className="form-group">
                            <label htmlFor="useremail">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>
                          <div className="form-group row mb-0">
                            <div className="col-12 text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="button"
                                onClick={() => this._login()}
                              >
                                Retrieve
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    Remember It ?{" "}
                    <Link to="/" className="font-weight-medium text-primary">
                      {" "}
                      Sign In here
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  loader = () => {
    this.setState({ loading: !this.state.loading });
  };

  toastHandler = (msg) => {
    toast.notify(<div>{msg}</div>, {
      position: "top",
      duration: 3000,
    });
  };

  async _login() {
    const { email } = this.state;
    if (email === "") {
      this.toastHandler("Please enter email");
      return false;
    } else {
      this.loader();
      const da = await fetch(
        "https://br-ad-bc.herokuapp.com/rec_keep_admin/api/users/getUser/" +
          email
      );
      const data = await da.json();
      const user = data.data;
      if (data.success === 1) {
        fetch(
          "https://br-ad-bc.herokuapp.com/rec_keep_admin/api/mailserver/mail/",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: user.email,
              encPass: user.encPass,
            }),
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.success === 1) {
              this.loader();
              this.toastHandler(
                "Your password was sent to your mail, get it to login!"
              );
            } else {
              this.loader();
              this.toastHandler(res.message);
            }
          });
      } else {
        this.loader();
        this.toastHandler(data.message);
      }
    }
  }
}

export default Recover;
